import { ReactElement } from 'react';
import { JSXMapSerializer } from '@prismicio/react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { RTPreformattedNode } from '@prismicio/client';

import Link from '@/components/atoms/Link';

export const PrismicSerializer: JSXMapSerializer = {
  preformatted: ({ node }) => (
    <div className="overflow-auto" dangerouslySetInnerHTML={{ __html: node.text }} />
  ),
  hyperlink: ({ node, children }): ReactElement | null => {
    if (!node.data.url) {
      return null;
    }

    return <Link href={node.data.url}>{children}</Link>;
  },
  label: ({
    node: {
      data: { label },
    },
    children,
  }) => {
    switch (label) {
      case 'quote':
        return (
          <div className="border-l-2 border-l-primary-600 pl-6">
            <p className="text-gray-600">{children}</p>
          </div>
        );
      default:
        return <span>{children}</span>;
    }
  },
  span: ({ text }) => {
    if (!text) {
      return <br />;
    }

    return <span>{text}</span>;
  },
};

export const preformattedBlogPostSerializer = ({ node }: { node: RTPreformattedNode }) => {
  // Extract the tweet ID from the embedded HTML
  const tweetIdMatch = node.text.match(/status\/(\d+)/);

  if (tweetIdMatch) {
    const tweetId = tweetIdMatch[1];

    return <TwitterTweetEmbed tweetId={tweetId} />;
  }

  return <div className="overflow-auto" dangerouslySetInnerHTML={{ __html: node.text }} />;
};
