'use client';
import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useController } from 'react-hook-form';

import { ArrowSwapHorizontalIcon, BtcIcon, DollarBillIcon } from '@/public/assets/svg';
import { CryptoCurrency } from '@/consts/api';
import { useLang } from '@/hooks/useLang';

import { clampValue } from '../CalculatorDesktop/utils';

import { useETransferCryptoAmount } from './hooks';
import { CalculatorMobileProps, FormCalculatorMobileProps } from './types';
import { makeFinalAmountValidator } from './utils';

const CalculatorMobile = React.forwardRef<HTMLInputElement, CalculatorMobileProps>(
  (
    {
      className,
      name,
      amount,
      setAmount,
      minValue,
      maxValue,
      amountCurrency,
      prices,
      disabled,
      onBlur,
    },
    ref,
  ) => {
    const { amount: btcAmount } = useETransferCryptoAmount(amount || 0, CryptoCurrency.BTC, prices);

    return (
      <div className={clsx('w-full bg-white', className)}>
        <div className="mb-5 flex h-[16.25rem] flex-col">
          <div
            className={clsx(
              'relative inline-block h-full w-full rounded-[.625rem] border border-gray-200 p-5',
              `after:absolute after:border after:border-gray-200 after:bg-white after:content-['']`,
              'after:bottom-[-1.125rem]',
              'after:left-0 after:right-0 after:ml-auto after:mr-auto after:h-9 after:w-[4.25rem]',
              'after:rounded-t-full after:rounded-bl-none after:rounded-br-none after:border-b-white',
            )}
          >
            <p className="mb-3 text-body-8 text-gray-500">You want to receive</p>
            <div className="flex flex-row justify-between">
              <input
                ref={ref}
                name={name}
                className="w-28 px-2 text-h6 font-bold"
                type="number"
                value={amount || ''}
                onChange={(event) => setAmount(event.target.valueAsNumber)}
                onBlur={(event) => {
                  setAmount(clampValue(event.target.valueAsNumber, minValue, maxValue));
                  onBlur?.(event);
                }}
                disabled={disabled}
              />
              <div className="flex flex-row items-center gap-3">
                <div className="flex items-center justify-center rounded-full bg-gray-100 p-1.5">
                  <DollarBillIcon className="h-5 w-5" />
                </div>
                <span className="text-body-5 font-semibold text-gray-600">{amountCurrency}</span>
              </div>
            </div>
          </div>
          <div className="relative z-30 flex h-8 w-full flex-shrink-0 items-center justify-center bg-white">
            <ArrowSwapHorizontalIcon className="absolute bottom-0 top-0 mb-auto mt-auto h-7 w-7 rotate-90 text-gray-500" />
          </div>
          <div
            className={clsx(
              'relative h-full w-full rounded-[.625rem] border border-gray-200 bg-gray-200 p-5',
              `before:absolute before:border before:border-gray-200 before:bg-white before:content-['']`,
              'before:top-[-1.125rem]',
              'before:left-0 before:right-0 before:ml-auto before:mr-auto before:h-9 before:w-[4.25rem]',
              'before:rounded-b-full before:rounded-tl-none before:rounded-tr-none before:border-t-white',
            )}
          >
            <p className="mb-3 text-body-8 text-gray-500">You need to send</p>
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <span className="text-center text-h6 font-bold text-gray-500">{btcAmount}</span>
              </div>
              <div className="flex flex-row items-center gap-3">
                <div className="flex items-center justify-center rounded-full bg-primary-600 p-1.5">
                  <BtcIcon className="h-5 w-5 text-white" />
                </div>
                <span className="text-body-5 font-semibold text-gray-600">BTC</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

CalculatorMobile.displayName = 'CalculatorMobile';

export const FormCalculatorMobile = ({
  name,
  restrictions,
  ...rest
}: FormCalculatorMobileProps) => {
  const lang = useLang();

  const validator = useMemo(
    () => makeFinalAmountValidator(restrictions.validAmounts, lang),
    [lang, restrictions.validAmounts],
  );

  const { field } = useController({
    name,
    rules: {
      validate: validator,
    },
  });

  return (
    <CalculatorMobile
      name={name}
      amount={field.value}
      setAmount={(value) => {
        field.onChange(value);
      }}
      ref={field.ref}
      onBlur={field.onBlur}
      disabled={field.disabled}
      {...rest}
    />
  );
};

export default CalculatorMobile;
