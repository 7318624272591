'use client';
import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogPanel, Transition } from '@headlessui/react';

import { ModalProps } from './types';

const Modal = ({
  children,
  isOpen,
  backdropClassName,
  containerClassName = 'px-6',
  contentClassName = 'p-12 w-full',
}: ModalProps) => {
  return (
    <Transition
      show={isOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="duration-300 ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog open={isOpen} onClose={() => {}} className="relative z-50 w-full">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div
          className={clsx('fixed inset-0 bg-gray-600/40', backdropClassName)}
          aria-hidden="true"
        />
        {/* Full-screen container to center the panel */}
        <div
          className={clsx(
            'fixed inset-0 flex w-screen items-center justify-center',
            containerClassName,
          )}
        >
          {/* The actual dialog panel */}
          <DialogPanel className={clsx('rounded-[0.625rem] bg-white', contentClassName)}>
            <div className="w-full">{children}</div>
          </DialogPanel>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
