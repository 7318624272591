import clsx from 'clsx';
import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren, ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean;
}

const Tab = ({ active, children, ...props }: Props) => {
  return (
    <button
      {...props}
      className={clsx(
        'w-full border-t border-t-gray-200 bg-white py-2.5 text-body-8 font-semibold',
        {
          'border-b-2 border-b-primary-600 text-primary-600': active,
          'border-b border-b-gray-200 text-gray-400': !active,
        },
      )}
    >
      {children}
    </button>
  );
};

export default Tab;
